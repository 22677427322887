import Rest from '@/services/Rest';

/**
 * @typedef {ConversionPixelService}
 */
export default class ConversionPixelService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/lexip'
}