import Rest from '@/services/Rest';

/**
 * @typedef {GetPixelService}
 */
export default class GetPixelService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/pixel/adm/'
}